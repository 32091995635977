import React, { lazy, Suspense } from 'react';
import './resources/css/App.sass';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { chapterData as Chapters } from './data/chapterData.js';
import { Container } from 'react-bootstrap';
// import { isIE } from 'react-device-detect';
import Navbar from './components/Navbar';
import Footer from './components/Footer'
import Suche from './pages/Suche';
import './resources/css/ImportBootstrap.scss';
import Loading from './components/ui-components/Loading';
// import Home from './pages/Home';
// import Overview from './pages/Overview';
// import Cartoon from './pages/Contentview';
// import Literatur from './pages/Literatur';
// import Datenschutz from './pages/Datenschutz';
// import Barrierefreiheit from './pages/Barrierefreiheit';
// import Intro from './pages/Intro';
// import Impressum from './pages/Impressum';
// import Anhang from './pages/Anhang';
import NotFound from './pages/NotFound';

const App = () => {
  const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
  const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

  if(isIE){
    return(
      <div className="App">
        <Navbar/>
        <div id='header-row'>
            <Container fluid="lg">
                <p>LaborStart | Sicherheit und Gesundheit im chemischen Praktikum</p>
            </Container>
        </div>
        <Routes>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
        <Footer/>
      </div>
    )
  }
  else{
    const Home = lazy(()=> import('./pages/Home'));
    const Overview = lazy(()=> import('./pages/Overview'));
    const Cartoon = lazy(()=> import('./pages/Contentview'));
    const Literatur = lazy(()=> import('./pages/Literatur'));
    const Datenschutz = lazy(()=> import('./pages/Datenschutz'));
    const Barrierefreiheit = lazy(()=> import('./pages/Barrierefreiheit'));
    const Intro = lazy(()=> import('./pages/Intro'));
    const Impressum = lazy(()=> import('./pages/Impressum'));
    const Anhang = lazy(()=> import('./pages/Anhang'));
    
    return (
      <div className="App">
        {/* <Loading /> */}
        <header>
          <Navbar/>
          <div id='header-row'>
              <Container fluid="lg">
                  <p>LaborStart | Sicherheit und Gesundheit im chemischen Praktikum</p>
              </Container>
          </div>
        </header>
        <main id="content-wrapper">
          <Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/home" element={<Home/>}/>
                <Route path="/uebersicht" element={<Overview/>} />
                <Route path="/overview" element={<Overview/>} />
                <Route path="/%C3%BCbersicht" element={<Overview/>} />
                <Route path="/übersicht" element={<Overview/>} />
                {Chapters.map((chapter, index) => {
                  return(
                    <Route key={index} path={chapter.uri} element={<Cartoon uri={chapter.uri} />}>
                      <Route path=":uri" element={<Cartoon />}/>
                    </Route>
                  )
                })}
                <Route path="/anhang" element={<Anhang/>}/>
                <Route path="/impressum" element={<Impressum/>}/>
                <Route path="/intro" element={<Intro/>}/>
                <Route path="/barrierefreiheit" element={<Barrierefreiheit/>}/>
                <Route path="/datenschutz" element={<Datenschutz/>}/>
                <Route path="/literatur" element={<Literatur/>}/>
                <Route exact path="suche" element={<Suche/>}/>
                <Route path="/404" element={<NotFound/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
          </Suspense>
        </main>
        <Footer/>
      </div>
    );
  }
}

export default App;
