import React, { useState, useEffect} from 'react';
import { FaSpinner } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Loading = () => {
    return (
        <div className='loading-animation'>
            <FaSpinner className='spinner-icon'/>
            <span className='sr-only'>Loading</span>
        </div> 
    )
}

export default Loading