export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function modulo(n, m) {
    let remain = n % m;
    return Math.floor(remain >= 0 ? remain : remain + m);
};

/**
 * sets a parameter to the url as "?search="
 * @param {any} value: search value
 */
export function setUrlParam(value){
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('search', value);
    let splitUrl = window.location.href.split("/");
    let pageName = splitUrl[splitUrl.length-1].split("?")[0];
    window.history.pushState(null, null, pageName+"?"+urlParams);
}

/**
 * gets the current search parameter
 */
export function getUrlParams(){
    const urlParams = new URLSearchParams(window.location.search);
    return (urlParams.get('search') !== null && urlParams.get('search').length !== 0) ? urlParams.get('search') : "";
}