import React, {useState, useEffect } from 'react';
import SearchButton from './SearchButton';
import { Link } from "react-router-dom";
import { searchData } from '../../resources/js/searchData';
import { getUrlParams, setUrlParam } from '../../resources/js/tools';
import "./Search.sass";
let elasticlunr = require('elasticlunr');
require('../../resources/js/lunr.stemmer.support.js')(elasticlunr);
require('../../resources/js/lunr.de.js')(elasticlunr);

const Search = (props) => {
    
    const [searchPhrase, setSearchPhrase] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [searchIndex, setSearchIndex] = useState(null);
    
    useEffect(() => {
        const urlParams = getUrlParams();
        if(props.getUrl && urlParams !== ""){
            if(searchPhrase === ""){
                setSearchPhrase(getUrlParams());
            }
        }
    }, [searchPhrase, props.getUrl]);

    const resCount = (props.resCount !== undefined) ? props.resCount : 100;

    const buildIndex = () => {
        // build elasticlunr index   
        const index = elasticlunr(function () {
            this.use(elasticlunr.de);   //use german stemmer
            this.addField('fullName');
            this.addField('name');
            this.addField('chapter');
            this.addField('teaser');
            this.addField('text');
            this.addField('uri');
            this.setRef('id');
            // this.saveDocument(false);
        });
        elasticlunr.clearStopWords();
    
        for (let i = 0; i < searchData.length; i++) {
            let element = searchData[i];
            element["id"] = i;
            index.addDoc(element);
        }

        return index;
    }

    useEffect(() => {
        if(searchIndex === null){
            if(searchPhrase !== ""){
                setSearchIndex(buildIndex())
            }
        }
        else{
            // console.time()
            if(searchPhrase.length >= 3){
            setSearchResults(searchFor(searchPhrase, searchIndex));
            }
            setUrlParam(searchPhrase);
            // console.timeEnd()
        }
    }, [searchPhrase, searchIndex])


    return (
        <div className='search'>
            <div className='search-bar-wrapper'>
                <div className='input-wrapper'>
                    <input className="searchbar" id="searchbar-input" type="search" placeholder="suchen ..." value={searchPhrase} aria-label="Suche" tabIndex={props.tabIndex} onChange={(e)=>{
                        setSearchPhrase(e.target.value);
                        if(e.target.value.length < 3) {
                            setUrlParam("");
                            setSearchResults([]);
                        }
                    }
                    }/>
                </div>
                <SearchButton color="#fff" tabIndex={props.tabIndex} />
            </div>
            {(searchResults.length > 0 && !props.resetSearch) ?
            <div className="search-results-wrapper">
                <h2 className='search-result-title'>Suchergebnisse</h2>
                <p>Es wurden <b>{searchResults.length} Ergebnisse</b> für diese Suche gefunden.</p>
                <div className="search-results-text">
                    {searchResults.map((result, index)=>{
                        if((index < resCount)){
                            return(
                                <div key={index} className='search-result'>
                                    <Link to={"/"+result.uri} className='search-result-heading' dangerouslySetInnerHTML= {{__html: result.name}}/>
                                    <p className='search-result-text' dangerouslySetInnerHTML= {{__html: result.text}} />
                                </div>
                            )
                        }
                        return null
                    })}
                    {((searchResults.length > resCount)) && <Link to={(getUrlParams() !== "") ? "/suche?search="+getUrlParams() : "/suche"} className='more-results-btn btn' onClick={()=>{setSearchPhrase(""); setSearchResults([]); props.setSidebar(false)}}>Mehr Ergebnisse</Link>}
                </div>
            </div> :
            (searchPhrase.length > 0 || !props.resCount) &&
            <div className="search-results-wrapper">
                <h2 className='search-result-title'>Suchergebnisse</h2>
                <p>Es wurden keine Ergebnisse gefunden.</p>
                {searchPhrase.length < 3 ? 
                    <p>Bitte geben Sie mehr als <b>drei Zeichen</b> ein, um zu suchen.</p> 
                    : 
                    <p>Zu diesem Suchbegriff wurde nichts gefunden. Versuchen Sie es mit einer anderen Formulierung oder Synonymen.</p>
                }
            </div>
            }
        </div>
    )
}

/**
 * search function: initiates elasticlunr search
 * builds index
 * adds json documents to index from "searchData.js" file
 * settings to boost different data points in search
 * @param {any} value search phrase
 * @returns {any} json object as search result(s)
 */
function searchFor(value, index){

    let searchRes = index.search(value, {
        fields: {
            name: {boost:5},
            chapter: {boost:1},
            text: {boost:1},
            uri: {boost:3},
            teaser: {boost:3}
        },
        expand:true
    });

    searchRes.sort(function(a, b) {
        let keyA = a.score,
            keyB = b.score;
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
    });

    let results = [];

    for (let i = 0; i < searchRes.length; i++) {
        let result = searchRes[i];
        let doc = index.documentStore.getDoc(result.ref);
        let resName = highlightSearchPhrase(value, doc.name);
        let resUri = doc.uri;
        let text = doc.text;
        let searchIndexOfText = text.toLowerCase().indexOf(value);
        let resText = highlightSearchPhrase(value, (searchIndexOfText >= 0) ? "... "+text.substr(searchIndexOfText, 200)+" ..." : text.substr(0, 200)+" ...");
        let resScore = result.score;
        let data = {"name": resName, "uri": resUri, "text": resText, "score":resScore};
        results.push(data);
    }

    return results;

}

/**
 * rewrites all words in search result texts, which are representing the search phrase into bold style
 * @param {any} searchPhrase
 */
function highlightSearchPhrase(searchPhrase, text){
    let searchPhraseUpperCase = searchPhrase.charAt(0).toUpperCase() + searchPhrase.slice(1);
    return text.split(searchPhrase).join("<b>"+searchPhrase+"</b>").split(searchPhraseUpperCase).join("<b>"+searchPhraseUpperCase+"</b>");
}

export default Search