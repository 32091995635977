import React from 'react'
import { Container } from 'react-bootstrap';
import './Footer.sass'

const Footer = () => {
  return (
    <footer id='footer' className='container-lg-fluid'>
      <Container fluid='container-lg' className="footer-links">
        <a href="/impressum" className="footer-link">Impressum</a>
        <a href="/datenschutz" className="footer-link">Datenschutz</a>
        <a href="/barrierefreiheit" className="footer-link">Barrierefreiheit</a>
    </Container>
  </footer>
  )
}

export default Footer