import React from 'react';
import { isIE } from 'react-device-detect';
import svg from '../resources/images/notfound-img.svg'

const NotFound = () => {
  return (
    <div className="contentview-wrapper container-md">
        <article className="content-sec detail-txt" id="error-page">
            <h1>Hups - 404</h1>
            <h2 id="404-heading">{(isIE) ? "Der genutzte Browser wird nicht unterstützt." : "Die gewünschte Seite wurde nicht gefunden."}</h2>
            {(isIE) ?
                    <section id="404-txt">
                        <p>Bitte wechseln Sie zu einem modernen Browser und besuchen Sie die Seite erneut.</p>
                        <p>Unterstütze Browser sind: <i><a href='https://www.microsoft.com/de-de/edge' target='_blank' rel='noreferrer'>Edge</a>, <a href='https://www.mozilla.org/de/firefox/new/' target='_ blank' rel='noreferrer'>Firefox</a>, <a href='https://www.google.com/intl/de_de/chrome/' target='_ blank' rel='noreferrer'>Chrome</a>, <a href='https://www.opera.com/de' target='_ blank' rel='noreferrer'>Opera</a>, <a href='https://www.apple.com/de/safari/' target='_ blank' rel='noreferrer'>Safari</a>, Samsung Internet, QQ Browser, Baidu Browser, KaiOS Browser</i></p>
                    </section>
                :
                    <section id="404-txt">
                        <p>Der Link auf diese Seite war offenbar ein Reinfall ... </p>
                        <p>Hier geht's <a href="/">zurück zur Übersicht</a></p>
                    </section>
            }
            <img className="404-img" src={svg} alt="404-Bild" style={{maxHeight:"33vh", margin:"2rem auto"}}/>
        </article>
    </div>
  )
}

export default NotFound