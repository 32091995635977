import React from 'react'
import "./DguvLogo.sass";

const DguvLogo = () => {
  return (
    <div className='logo-btn-wrapper'>
        <a className="dguv-logo navbar-btn" href='https://www.dguv.de/' target='_blank' rel='noreferrer' title='mehr zur DGUV erfahren'>
            <img className='' src={require('../../resources/images/logo_dguv.png')} alt='DGUV Logo' width={301} height={132} />
        </a>
    </div>
  )
}

export default DguvLogo