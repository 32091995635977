import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from './components/ui-components/ScrollToTop'
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <ScrollToTop/>
        <App tab="home" />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);