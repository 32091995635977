import React from 'react';
import { FaTimesCircle } from "react-icons/fa";
import "./CloseButton.sass";

const CloseButton = (props) => {
  return (
    <button className={"close-btn "+props.className} onClick={ props.action } title="Ansicht schließen" tabIndex={props.tabIndex}>
        <FaTimesCircle />
        <span className='sr-only'>Menü schließen</span>
    </button>
  )
}

export default CloseButton