import React from 'react';
import { Container } from 'react-bootstrap';
import Search from '../components/ui-components/Search';

const Suche = () => {
  require('./Pages.sass');
  return (
    <div className="contentview-wrapper txt-only">
        <div className="content-top-wrapper">
            <Container fluid="md">
                <h1 className="searchview-heading content-top-heading">Suche</h1>
            </Container>
        </div>
        <Container fluid="md">
          <section className="content-sec" id="search">
              <Search getUrl={true} />
          </section>
        </Container>
    </div>
  )
}

export default Suche