import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { chapterData as Chapters } from '../../data/chapterData.js';
import CloseButton from './CloseButton';
import Icon from './DguvIcons';
import Search from './Search';
import "./NavOverlay.sass";

const NavOverlay = (props) => {

  const [activeChapter, setActiveChapter] = useState("");

  return (
    <div 
      className={'overlay p-3'+(props.active ? " active" : "")}
      onBlur={(e)=>{
        if (!e.currentTarget.contains(e.relatedTarget)) { // focus left self
          props.setSidebar(false)
        }
      }}
      tabIndex={-1}
      aria-hidden={(props.active) ? "false" : "true"}
      style={{outline:"none"}}
    >
      <div className='overlay-wrapper'>
            <nav className="overlay-nav" aria-label='Overlay Navigation'>
                <div className='overlay-nav-btn-wrapper'>
                  <Link to="/" className='home-btn' tabIndex={(props.active)? 0 : -1} title='zur Startseite'>
                    <Icon icon="home" viewBox="0 0 124 124" size={32} />
                  </Link>
                  <Link to="/uebersicht" className='overview-btn' tabIndex={(props.active)? 0 : -1} onClick={()=>props.setSidebar(false)} title='zu den Themen'>
                      <BsGrid3X3GapFill size={24} />
                  </Link>
                </div>
                <CloseButton action={()=>props.setSidebar(false)} tabIndex={(props.active)? 0 : -1}></CloseButton>
            </nav>
            {(props.currPath !== "/suche") && <Search resCount={3} setSidebar={props.setSidebar} tabIndex={(props.active)? 0 : -1} />}
            <div className='menu-links-wrapper'>
                <nav className='menu-links' aria-label='Themenbereiche Navigation'>
                    {Chapters.map((chapter, index) => {
                        if(chapter.subChapters !== undefined){
                            return (
                              <div key={index} className="chapter">
                                  <button 
                                    className={((activeChapter === chapter.name) && props.active) ? "main-chapter-link menu-chapter-heading chapter-overview clicked" : "main-chapter-link menu-chapter-heading chapter-overview"} 
                                    onClick={()=>{(activeChapter === chapter.name) ? setActiveChapter("") : setActiveChapter(chapter.name)}}
                                    // onFocus={()=>setActiveChapter("")}
                                    tabIndex={(props.active)? 0 : -1}>
                                    <span>{chapter.name}</span>
                                    <MdKeyboardArrowRight size={48} className='arrow-left-lightblue-icon' aria-hidden="true" />
                                  </button>
                                  <nav className={(activeChapter === chapter.name && props.active) ? "subChapterOverview active" : "subChapterOverview"} aria-label={"Navigation zum Thema "+chapter.name}>
                                    <div className='subChapter-heading-wrapper'>
                                      <button className='backToOverview' onClick={()=>setActiveChapter("")} title='zurück' tabIndex={(activeChapter === chapter.name && props.active) ? 0 : -1}>
                                        <MdKeyboardArrowLeft size={48} className='arrow-left-lightblue-icon' aria-hidden="true" />
                                        <h2 className="subChapter-heading">{chapter.name}</h2>
                                      </button>
                                    </div>
                                      {chapter.subChapters.map((subChapter, subIndex) => {
                                          return(
                                            <Link key={subIndex} className='menu-link' to={""+subChapter.uri} tabIndex={(activeChapter === chapter.name && props.active) ? 0 : -1} onClick={()=>props.setSidebar(false)}>
                                                {subChapter.name}
                                            </Link>
                                          )
                                      })}
                                    
                                  </nav>
                              </div>
                            )
                        }else return null
                    })}
                </nav>
                <nav className='menu-bottom-links' aria-label="Navigation zu den allgemeinen Informationen der Seite">
                  <Link className='menu-bottom-link' to='intro' tabIndex={(props.active)?0:-1} onClick={()=>props.setSidebar(false)}>Vorbemerkungen & Einführung</Link>
                  <Link className='menu-bottom-link' to='literatur' tabIndex={(props.active)?0:-1} onClick={()=>props.setSidebar(false)}>Literaturhinweise & weitere Informationen</Link>
                  <Link className='menu-bottom-link' to='datenschutz' tabIndex={(props.active)?0:-1} onClick={()=>props.setSidebar(false)}>Datenschutzerklärung</Link>
                  <Link className='menu-bottom-link' to='barrierefreiheit' tabIndex={(props.active)?0:-1} onClick={()=>props.setSidebar(false)}>Barrierefreiheit</Link>
                  <Link className='menu-bottom-link' to='impressum' tabIndex={(props.active)?0:-1} onClick={()=>props.setSidebar(false)}>Impressum</Link>
                </nav>
            </div>
        </div>
        <div className='menu-background'>
            <img className='menu-background-img' src={require('../../resources/images/characters/charly.png')} width={350} height={600} alt='' />
        </div>
    </div>
  )
}

export default NavOverlay