export const iconsLib = {
    home:"M81,89.972l-20,-0l0,-29.979l-20,-0l0,29.979l-20,-0l0,-45.896l30,-29.979l30,29.979l0,45.896Zm18.535,-41.506l-48.535,-48.501l-48.535,48.501c-1.953,1.951 -1.953,5.115 0,7.066c1.951,1.951 5.119,1.951 7.07,-0l1.465,-1.464l0,45.897l80,-0l0,-45.897l1.465,1.464c0.975,0.975 2.256,1.463 3.535,1.463c1.279,-0 2.56,-0.488 3.535,-1.463c1.953,-1.951 1.953,-5.115 0,-7.066",
    arrowTop:"M57.12,20.572L57.12,123.517L67.12,123.517L67.12,20.496L97.59,50.966L104.662,43.894L69.23,8.462L69.234,8.458L62.163,1.386L19.721,43.828L26.792,50.9L57.12,20.572Z",
    document: "M70.785,2L14.3,2L14.3,122L110.3,122L110.3,41.515L70.785,2ZM26.3,110L26.3,14L62.3,14L62.3,50L98.3,50L98.3,110L26.3,110ZM86.3,86L38.3,86L38.3,98L86.3,98L86.3,86ZM86.3,62L38.3,62L38.3,74L86.3,74L86.3,62ZM74.3,22.485L89.815,38L74.3,38L74.3,22.485Z",
    warning: "M61.5,121.5C28.416,121.5 1.5,94.584 1.5,61.5C1.5,28.416 28.416,1.5 61.5,1.5C94.584,1.5 121.5,28.416 121.5,61.5C121.5,94.584 94.584,121.5 61.5,121.5ZM61.5,13.5C35.033,13.5 13.5,35.033 13.5,61.5C13.5,87.967 35.033,109.5 61.5,109.5C87.967,109.5 109.5,87.967 109.5,61.5C109.5,35.033 87.967,13.5 61.5,13.5ZM61.5,85.5C58.186,85.5 55.5,88.187 55.5,91.5C55.5,94.813 58.186,97.5 61.5,97.5C64.814,97.5 67.5,94.813 67.5,91.5C67.5,88.187 64.814,85.5 61.5,85.5ZM67.5,25.5L55.5,25.5L55.5,73.5L67.5,73.5L67.5,25.5Z",
    ut: "M122,122L2,122L2,2L122,2L122,122ZM20.132,110L103.868,110C107.256,110 110,107.254 110,103.868L110,20.132C110,16.746 107.256,14 103.868,14L20.132,14C16.746,14 14,16.746 14,20.132L14,103.868C14,107.254 16.746,110 20.132,110ZM96.295,45.182L88.305,45.182L88.305,86L77.775,86L77.775,45.182L68.851,45.182L68.851,38L98,38L96.295,45.182ZM61.19,77.889C59.894,81.294 55.001,86 44.206,86C36.578,86 30.965,84.019 28.159,80.241C26.504,78.013 26,76.279 26,72.131L26,38L36.291,38L36.291,70.459C36.291,73.122 36.578,74.669 37.155,75.597C38.306,77.517 40.679,78.57 43.99,78.57C48.38,78.57 51.187,76.713 51.617,73.555C51.763,72.626 51.763,72.007 51.763,69.716L51.763,38L61.981,38L61.981,71.141C61.981,75.227 61.91,76.032 61.19,77.889Z",
    user:"M104,73.529L73.916,73.529L62,85.436L50.084,73.529L20,73.529C10.075,73.529 2,81.599 2,91.52L2,121.5L122,121.5L122,91.52C122,81.599 113.925,73.529 104,73.529ZM62,61.462C78.542,61.462 92,48.012 92,31.481C92,14.95 78.542,1.5 62,1.5C45.458,1.5 32,14.95 32,31.481C32,48.012 45.458,61.462 62,61.462ZM62,13.5C71.925,13.5 80,21.566 80,31.481C80,41.395 71.925,49.462 62,49.462C52.075,49.462 44,41.395 44,31.481C44,21.566 52.075,13.5 62,13.5Z",
    train: "M26.101,37.496l-0,-18c-0,-3.308 2.691,-6 6,-6l59.999,0c3.308,0 6,2.692 6,6l-0,18l-71.999,0Zm48.273,24l11.999,-0l0,12l-11.999,-0l-0,-12Zm-24.011,12l-12,-0l0,-12l12,-0l0,12Zm41.737,-71.999l-59.999,-0c-9.926,-0 -18,8.074 -18,17.999l-0,62.485l15.514,15.514l7.561,0l-7.977,19.457l11.103,4.552l9.844,-24.009l24.279,0l9.845,24.009l11.103,-4.552l-7.978,-19.457l7.19,0l15.515,-15.514l-0,-62.485c-0,-9.925 -8.075,-17.999 -18,-17.999",
    times: "M62.011,54.429L8.004,0.422L0.942,7.485L54.949,61.492L0.942,115.499L8.004,122.561L62.011,68.554L116.018,122.561L123.08,115.499L69.073,61.492L123.08,7.485L116.018,0.422L62.011,54.429Z",
    search: "M14.014,49.361c0,-19.823 16.154,-35.95 36.009,-35.95c19.855,0 36.008,16.127 36.008,35.95c0,19.823 -16.153,35.949 -36.008,35.949c-19.855,0 -36.009,-16.126 -36.009,-35.949m107.997,55.122l-30.719,-30.669c4.276,-7.164 6.741,-15.525 6.741,-24.453c0,-26.43 -21.538,-47.932 -48.01,-47.932c-26.473,0 -48.012,21.502 -48.012,47.932c-0,26.43 21.539,47.931 48.012,47.931c8.838,0 17.12,-2.406 24.241,-6.584l30.773,30.721l16.974,-16.946Z",
    print: "M121.5,43.5C121.5,33.575 113.425,25.5 103.5,25.5L97.5,25.5L97.5,1.5L25.5,1.5L25.5,25.5L19.5,25.5C9.575,25.5 1.5,33.575 1.5,43.5L1.5,109.5L25.5,109.5L25.5,121.5L97.5,121.5L97.5,109.5L121.5,109.5L121.5,43.5ZM85.5,109.5L37.5,109.5L37.5,85.5L85.5,85.5L85.5,109.5ZM109.5,97.5L97.5,97.5L97.5,73.5L25.5,73.5L25.5,97.5L13.5,97.5L13.5,43.5C13.5,40.192 16.192,37.5 19.5,37.5L103.5,37.5C106.808,37.5 109.5,40.192 109.5,43.5L109.5,97.5ZM91.5,49.5C88.186,49.5 85.5,52.187 85.5,55.5C85.5,58.813 88.186,61.5 91.5,61.5C94.814,61.5 97.5,58.813 97.5,55.5C97.5,52.187 94.814,49.5 91.5,49.5Z",
    plus: "M98.3,67.55l-30.25,-0l-0,30.25l-12.1,-0l-0,-30.25l-30.25,-0l-0,-12.1l30.25,-0l-0,-30.25l12.1,-0l-0,30.25l30.25,-0l-0,12.1Zm-36.3,-66.55c-33.36,-0 -60.5,27.14 -60.5,60.5c-0,33.36 27.14,60.5 60.5,60.5c33.359,-0 60.5,-27.14 60.5,-60.5c-0,-33.36 -27.141,-60.5 -60.5,-60.5",
    phone: "M122,89.287c-0,-2.57 -1,-4.984 -2.818,-6.8l-14.743,-14.743c-1.814,-1.815 -4.229,-2.815 -6.798,-2.815l-0.003,0c-2.568,0 -4.984,1.002 -6.798,2.819l-16.601,16.615l-34.648,-34.65l16.571,-16.782c3.676,-3.749 3.648,-9.82 -0.065,-13.532l-14.586,-14.588c-3.746,-3.747 -9.847,-3.75 -13.598,0l-14.297,14.297l-0.005,-0.005c-0.104,0.107 -0.207,0.214 -0.309,0.322l-0.003,0.001c-7.29,7.637 -11.299,17.637 -11.299,28.211c0,10.576 4.009,20.575 11.299,28.212l0.003,0.002c0.102,0.107 0.205,0.214 0.309,0.321l0.005,-0.005l33.836,33.838l0.764,0.759c7.626,7.246 17.619,11.236 28.143,11.236c10.723,-0 20.859,-4.123 28.533,-11.611l-0.005,-0.005l14.295,-14.297c1.818,-1.817 2.818,-4.232 2.818,-6.8",
    minus: "M98,67.5l-72,0l-0,-12l72,0l-0,12Zm-36,-66c-33.085,0 -60,26.916 -60,60c-0,33.084 26.915,60 60,60c33.084,-0 60,-26.916 60,-60c-0,-33.084 -26.916,-60 -60,-60",
    mail: "M14,98.5l0,-59.915l35.087,35.088c3.56,3.558 8.238,5.339 12.913,5.339c4.676,-0.001 9.354,-1.781 12.914,-5.34l35.086,-35.087l0,59.915l-96,0Zm91.115,-72l-38.686,38.686c-2.442,2.44 -6.415,2.443 -8.857,0.001l-38.687,-38.687l86.23,-0Zm16.885,-12l-120,-0l-0,96l120,0l0,-96Z",
    location:"M62.195,54.301c-6.617,-0 -12,-5.384 -12,-12c0,-6.617 5.383,-12 12,-12c6.617,-0 12,5.383 12,12c0,6.616 -5.383,12 -12,12m-0.198,-52.801c-22.057,0.001 -40.002,17.945 -40.002,40.003c0,7.286 1.978,14.415 5.722,20.624l34.28,59.374l34.242,-59.308c3.769,-6.226 5.761,-13.379 5.761,-20.69c0,-22.058 -17.944,-40.003 -40.003,-40.003",
    linkInt: "M110.322,109.909L13.592,109.909L13.592,13.178L49.866,13.178L49.866,1.086L1.5,1.086L1.5,122L122.413,122L122.413,73.634L110.322,73.634L110.322,109.909ZM49.866,37.36L37.774,37.36L37.774,77.175L37.688,77.262L37.774,77.348L37.774,85.726L46.152,85.726L46.238,85.812L46.324,85.726L86.14,85.726L86.14,73.635L58.416,73.635L122.5,9.551L113.95,1L49.866,65.085L49.866,37.36Z",
    linkExt: "M85.7,110.9L13.1,110.9L13.1,38.3L49.4,38.3L49.4,26.2L1,26.2L1,123L97.8,123L97.8,74.6L85.7,74.6L85.7,110.9ZM73.6,2L73.6,14.1L101.344,14.1L33.04,82.404L41.597,90.96L109.9,22.656L109.9,50.4L122,50.4L122,2L73.6,2Z",
    info: "M62,122C28.916,122 2,95.084 2,62C2,28.916 28.916,2 62,2C95.084,2 122,28.916 122,62C122,95.084 95.084,122 62,122ZM62,14C35.533,14 14,35.533 14,62C14,88.467 35.533,110 62,110C88.467,110 110,88.467 110,62C110,35.533 88.467,14 62,14ZM68,50L56,50L56,98L68,98L68,50ZM62,38C65.314,38 68,35.313 68,32C68,28.687 65.314,26 62,26C58.686,26 56,28.687 56,32C56,35.313 58.686,38 62,38Z",
    hands:"M109.959,74.033L67.812,74.107L67.812,68.134L97.909,68.134C101.057,68.134 103.892,65.832 104.166,62.689C104.473,59.146 101.691,56.174 98.219,56.174L31.985,56.174L39.419,48.73L39.66,48.5C41.886,46.271 42.266,42.635 40.241,40.22C37.956,37.496 33.891,37.364 31.435,39.824L30.975,40.272L13.363,57.912L13.37,57.919C10.176,61.156 8.199,65.6 8.199,70.51L8.199,98.026L8.203,98.028C8.203,98.046 8.199,98.063 8.199,98.081C8.199,111.292 18.891,122 32.082,122C32.085,122 32.09,121.999 32.096,121.999L32.097,122L52.381,121.952L92.223,121.952C95.697,121.952 98.479,118.981 98.171,115.436C97.898,112.296 95.063,109.992 91.914,109.992L67.812,109.992L67.812,104.016L104.187,104.054C107.485,104.054 110.159,101.377 110.159,98.075C110.159,94.771 107.485,92.094 104.187,92.094L67.812,92.057L67.812,86.068L109.65,85.992C112.799,85.992 115.634,83.69 115.907,80.548C116.215,77.005 113.433,74.033 109.959,74.033ZM96.25,50.17L98.546,50.17C101.891,50.17 105.106,51.586 107.366,54.054C107.672,54.389 107.953,54.741 108.217,55.104L114.452,48.862C116.909,46.402 116.774,42.333 114.055,40.044C111.643,38.016 108.012,38.394 105.785,40.624L96.25,50.17ZM9.451,53.667L20.23,42.871L20.23,40.595L20.239,40.262C20.238,37.11 17.939,34.269 14.801,33.996C11.262,33.687 8.295,36.47 8.296,39.949L8.286,40.592L8.29,54.83L9.451,53.667ZM70.914,50.17L87.827,50.17L104.634,33.38C106.964,31.046 106.964,27.26 104.631,24.924C102.297,22.589 98.517,22.589 96.186,24.923L70.914,50.17ZM45.161,36.358C48.42,40.243 48.711,45.822 46.2,50.17L69.96,50.17L66.206,46.411L95.737,16.741C97.964,14.513 98.342,10.878 96.317,8.462C94.031,5.74 89.965,5.608 87.509,8.066L57.758,37.956L53.539,33.732L74.821,12.426C77.047,10.198 77.426,6.562 75.399,4.147C73.115,1.423 69.049,1.291 66.591,3.75L38.1,32.274C40.836,32.765 43.337,34.183 45.161,36.358Z",
    eye: "M61.52,91.583C40.856,91.583 22.396,82.269 10.166,67.616L1.572,76.117C7.382,82.865 14.348,88.601 22.144,93.076L1.748,113.456L10.229,121.929L33.67,98.504C42.336,101.758 51.715,103.567 61.52,103.567C85.512,103.567 107.033,92.895 121.487,76.097L112.873,67.617C100.644,82.246 82.164,91.583 61.52,91.583ZM79.516,61.777C79.516,51.867 71.447,43.803 61.529,43.803C51.61,43.803 43.541,51.867 43.541,61.777C43.541,71.688 51.61,79.752 61.529,79.752C71.447,79.752 79.516,71.688 79.516,61.777ZM101.275,30.685L121.572,10.402L113.093,1.929L89.8,25.204C81.02,21.851 71.498,19.988 61.537,19.988C37.545,19.988 16.025,30.659 1.572,47.457L10.184,55.937C22.413,41.308 40.893,31.971 61.537,31.971C82.2,31.971 100.662,41.285 112.891,55.939L121.487,47.438C115.765,40.793 108.924,35.129 101.275,30.685Z",
    book:"M122.484,25.604L98.23,35.454C97.186,16.336 81.34,1.105 62.007,1.105C42.756,1.105 26.965,16.21 25.801,35.214L1.499,25.209L1.516,97.183L62.044,122.104L122.502,97.551L122.484,25.604ZM68.051,106.581L68.051,60.794L110.388,43.6L110.399,89.383L68.051,106.581ZM13.603,43.301L55.951,60.735L55.951,106.487L13.613,89.055L13.603,43.301ZM74.093,97.868L104.339,85.405L104.339,72.349L74.093,84.811L74.093,97.868ZM41.41,62.673L32.39,71.713L28.498,67.811L19.941,76.385L32.39,88.861L49.967,71.246L41.41,62.673ZM74.093,78.014L104.339,65.551L104.339,52.495L74.093,64.957L74.093,78.014ZM37.826,37.461C37.826,24.099 48.673,13.23 62.007,13.23C75.34,13.23 86.189,24.099 86.189,37.461C86.189,38.455 86.12,39.447 86.001,40.421L62.061,50.143L37.997,40.236C37.893,39.316 37.826,38.389 37.826,37.461Z",
    calendar:"M98,14.5L98,2.5L86,2.5L86,14.5L38,14.5L38,2.5L26,2.5L26,14.5L2,14.5L2,110.372C2,117.06 7.441,122.5 14.127,122.5L109.873,122.5C116.559,122.5 122,117.06 122,110.372L122,14.5L98,14.5ZM110,110.372C110,110.443 109.942,110.5 109.873,110.5L14.127,110.5C14.058,110.5 14,110.443 14,110.372L14,50.5L110,50.5L110,110.372ZM68,86.5L56,86.5L56,98.5L68,98.5L68,86.5ZM98,86.5L86,86.5L86,98.5L98,98.5L98,86.5ZM38,86.5L26,86.5L26,98.5L38,98.5L38,86.5ZM38,62.5L26,62.5L26,74.5L38,74.5L38,62.5ZM68,62.5L56,62.5L56,74.5L68,74.5L68,62.5ZM98,62.5L86,62.5L86,74.5L98,74.5L98,62.5Z",
    download:"M109.5,73.973L109.5,109.993L13.5,109.993L13.5,73.973L1.5,73.973L1.5,121.999L121.5,121.999L121.5,73.973L109.5,73.973ZM53.014,89.597L55.5,92.085L55.5,92.205L55.621,92.205L61.5,98.087L67.38,92.205L67.5,92.205L67.5,92.085L95.442,64.127L86.956,55.637L67.5,75.104L67.5,2L55.5,2L55.5,75.104L36.044,55.637L27.559,64.127L53.014,89.597Z",
    en: "M122,122L2,122L2,2L122,2L122,122ZM20.132,110L103.868,110C107.256,110 110,107.254 110,103.868L110,20.132C110,16.746 107.256,14 103.868,14L20.132,14C16.746,14 14,16.746 14,20.132L14,103.868C14,107.254 16.746,110 20.132,110ZM26.002,86.078L26.002,38.078L54.738,38.078L53.432,45.067L36.161,45.067L36.161,58.535L50.602,58.535L50.602,65.524L36.306,65.524L36.306,78.722L55.392,78.722L55.392,86.078L26.002,86.078ZM87.135,86.078L78.278,67.706C75.375,61.699 72.249,54.929 71.282,51.959C71.654,55.897 71.728,61.561 71.803,65.566L72.1,86.078L62.053,86.078L62.053,38.078L73.588,38.078L83.488,57.278C86.094,62.389 88.104,67.43 89.144,70.883C88.772,67.154 88.401,61.007 88.326,57.692L88.104,38.078L98.002,38.078L98.002,86.078L87.135,86.078Z"
    };

    export default iconsLib;