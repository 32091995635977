import React, { useState, useEffect } from 'react';
import { HiOutlineMenu, HiShare } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Icon from './ui-components/DguvIcons';
import './Navbar.sass'

import SearchButton from './ui-components/SearchButton';
import NavOverlay from './ui-components/NavOverlay';
import DguvLogo from './ui-components/DguvLogo.js';


function Navbar() {
    let currPath = useLocation().pathname;
    const [sidebar, setSidebar] = useState(false);  
    
    if(sidebar && window.innerWidth < 1400){
        document.body.style.overflowY = "hidden";
    }
    else{
        document.body.style.overflowY = "auto";
    }

    // hide sidebar if esc-key is pressed
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setSidebar(false)
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    return (
        <div id='navbar'>
            <Container fluid="lg" className='navbar-wrapper'>
                    <DguvLogo />
                    <div className='nav-btn-wrapper'>
                        <button className="navbar-btn" title="diese Seite teilen" onClick={shareSite}>
                            <HiShare />
                            <span className='sr-only'>Seite teilen</span>
                        </button>
                        <SearchButton className="navbar-btn"></SearchButton>
                        {(currPath !== "/" && currPath !== "/home") &&
                        <Link to='/' className="navbar-btn" title="Zur Startseite">
                            <Icon icon="home" viewBox="0 0 124 124" size={32} />
                        </Link>}
                        <button className="navbar-btn" title="Menü öffnen" onClick={()=>setSidebar(!sidebar)}>
                            <HiOutlineMenu />
                            <span className='sr-only'>Menü öffnen</span>
                        </button>
                    </div>
            </Container>
            <NavOverlay 
                active={sidebar}
                setSidebar={setSidebar}
                currPath={currPath}
                />
        </div>
    )
}

function shareSite(){
    if(navigator.share) {
        navigator.share({
            title: 'Laborstart',
            text: 'Infos für Sicherheit und Gesundheit im chemischen Praktikum',
            url: 'https://laborstart.bgrci.de'
        })
    }
    else{
        if(navigator.clipboard){
            navigator.clipboard.writeText('https://laborstart.bgrci.de');
        }
        else{
            
            let text = "https://laborstart.bgrci.de";
            let textArea = document.createElement("textarea");
            textArea.value = text;
            
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                let successful = document.execCommand('copy');
                let msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        }
        tempAlert('Copied to clipboard');
    }
}

function tempAlert(){
    let element = document.createElement("div");
    element.classList.add("popup-overlay");
    let icon = document.createElement("i");
    icon.classList.add("fas", "fa-copy");
    // icon.onclick = (fade(element));
    icon.setAttribute("onClick", "document.querySelector('.popup-overlay').remove()");
    element.innerHTML = "Link wurde kopiert!";
    element.append(icon);
    document.querySelector(".App").append(element);
    window.setTimeout(function() {
        fade(document.querySelector(".popup-overlay"))
    }, 2000);
}

  function fade(element) {
    let op = 1;  // initial opacity
    let timer = setInterval(function () {
        if (op <= 0.1){
            clearInterval(timer);
            element.style.display = 'none';
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op -= op * 0.1;
    }, 50);
}

export default Navbar