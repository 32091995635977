import React from 'react';
import Icon from './DguvIcons';
import { Link } from "react-router-dom";
import { getUrlParams } from '../../resources/js/tools';
import "./SearchButton.sass";

const SearchButton = (props) => {
  const urlParams = getUrlParams();
  return (
    <Link to={(urlParams !== "") ? "/suche?search="+getUrlParams() : "/suche"} className={(props.className) ? props.className+" search-btn" : "search-btn"} title="Suche starten" tabIndex={props.tabIndex}>
        <Icon icon="search" viewBox="0 0 124 124" size={32} />
        <span className='sr-only sr-only-light'>Suche starten</span>
    </Link>
  )
}

export default SearchButton
